import React from 'react'
import { Container, Row, Col } from '@kogk/common'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import styles from './Footer.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { useGlobalData } from '@src/data/global-data'
import {
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
  LogoWhite,
  EULogoFooter
} from '@src/assets'
import cn from 'classnames'

export default ({ isFrontpage, hasEuText }) => {
  const { t, language } = useLanguage()
  const { stringUrls } = useGlobalData()
  const logoUrl = language === 'is' ? `/${language}` : '/'

  const footerClasses = cn(
    { 'container-dark-blue': isFrontpage },
    styles.footerWrapper
  )

  return (
    <footer className={footerClasses}>
      <Container>
        <Row className='justify-content-between flex-lg-row flex-column-reverse align-items-center'>
          <Col col={{ lg: 3 }} className='pb-2 pt-1 mt-2 '>
            <Link to={logoUrl} >
              <LogoWhite />
            </Link>
          </Col>

          <Col col={{ lg: 4 }} className='d-flex justify-content-lg-end' >
            <Link className='mr-2' to={t(`footer|facebook link`)} >
              <Facebook />
            </Link>
            <Link className='mx-2' to={t(`footer|twitter link`)}>
              <Twitter />
            </Link>
            <Link className='mx-2' to={t(`footer|linkedin link`)}>
              <Linkedin />
            </Link>
            <Link className='mx-2' to={t(`footer|instagram link`)}>
              <Instagram />
            </Link>
          </Col>
        </Row>
        <div className={styles.divider} />
        <Row>
          <Col col={{ xs: 12, lg: 4 }} className={styles.info}>
            <p className='hdln--6'>{t(`footer|name`)}</p>
            <p className='hdln--6'>{t(`footer|address`)}</p>
            <p className='hdln--6'>{t(`footer|info`)}</p>
            <Link to={stringUrls[`${language}|footer|policy`]}>
              <p className='hdln--6'>{t(`footer|policy`)}</p>
            </Link>
          </Col>
          {hasEuText && hasEuText.length > 0 &&
            <Col col={{ xs: 10, lg: 8 }} className='d-md-flex mt-2 mt-lg-1'>
              <img className={cn('', styles.flag)} src={EULogoFooter} />
              <p className={styles.euText}>{t(`footer|eu text`)}</p>
            </Col>
          }
        </Row>
      </Container>
    </footer>
  )
}

import React, { useRef, useState, useEffect } from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import fetch from 'isomorphic-unfetch'
import { navigate } from 'gatsby'
import { Arrow } from '@src/assets'
import { countryList, states } from '@src/utils/constants'
import cn from 'classnames'
import styles from './ContactUsForm.module.scss'
import { countryInEU } from '@src/utils'

const {
  GATSBY_FORMS_API_HOST = 'forms-test.carbfix.com'
} = process.env

const SHOW_BANNER = false

const performPostRequest = async data => {
  const response = await fetch(GATSBY_FORMS_API_HOST, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error('Error sending request, status ' + response.status)
  }

  return response
}

const getFormData = (form, formType, country, state, sector, co2Emission, timeframe, eu) => {
  console.log(form, formType, country, state, sector, co2Emission, timeframe, eu)
  const formData = new FormData(form)
  formData.append('category', formType)
  formData.append('country', country)
  formData.append('state', state)
  formData.append('sector', sector)
  formData.append('co2Emission', co2Emission)
  formData.append('timeframe', timeframe)
  formData.append('eu_ets', eu)
  return Array.from(formData.entries()).reduce((acc, [k, v]) => {
    acc[k] = v
    return acc
  }, {})
}

const CustomDropdown = ({ options, onSelect, value, isSearchable = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const dropdownRef = useRef(null);

  // Event handler for closing dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
    setSearchInput('');
  };

  const filteredOptions = options.filter(option => 
    option.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className={cn("dropdown-selected", value === 'Please select' && styles.pleaseSelect)} onClick={() => setIsOpen(!isOpen)}>
        <div>{value}</div>
        <div className={cn("dropdown-arrow", value === 'Please select' && styles.arrowSelect)}><Arrow /></div>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {isSearchable && (
            <input
              type="text"
              className="dropdown-search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Type to search..."
            />
          )}
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};



const FORM_STATE_DEFAULT = 0
const FORM_STATE_SENDING = 1
const FORM_STATE_ERROR = 2
const FORM_STATE_SUCCESS = 4

export default ({ text, title, purchaseOptions }) => {
  const { t } = useLanguage()
  const [ formState, setFormState ] = useState(FORM_STATE_DEFAULT)

  useEffect(() => {
    if (formState === FORM_STATE_SUCCESS) {
      navigate(t`Contact us form|success url`)
    }
  })

  const purchaseOptionsList = purchaseOptions?.split(',') ?? []

  const [formType, setFormType] = useState('General')
  const [country, setCountry] = useState('Please select')
  const [state, setState] = useState('Please select')
  const [sector, setSector] = useState('Please select')
  const [siteCountry, setSiteCountry] = useState('Please select')
  const [co2Emission, setCo2Emission] = useState('Please select')
  const [timeframe, setTimeframe] = useState('Please select')
  const [eu, setEu] = useState('Please select')

  console.log(eu)

  return (
    <FancyShow>
      <Container>
        {SHOW_BANNER && (
          <Row>
            <Col
              className='p-3 announcementBanner'
              col={{ lg: 10 }}
              offset={{ lg: 1 }}
            >
              <div className='announcementBanner'>
                <h3>{t(`announcement|heading`)}</h3>
                <p className='parag--big'>{t(`announcement|text`)}</p>
              </div>
            </Col>
          </Row>
        )}
        <Row className='pt-2'>
          <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
            <h2>{title}</h2>
            <p>{text}</p>
          </Col>
          <Col col={{ lg: 8 }} offset={{ lg: 2 }} className='pb-5'>
            {formState === FORM_STATE_ERROR && (
              <div>
                {t`Contact us form|Sending error`}
              </div>
            )}

            {formState === FORM_STATE_SUCCESS && (
              <div>
                <h2>{t`Contact us form|Send success heading`}</h2>
                <p>{t`Contact us form|Send success text`}</p>
              </div>
            )}

            {formState !== FORM_STATE_SUCCESS && (
              <Form
                t={t}
                formType={formType}
                setFormType={setFormType}
                country={country}
                setCountry={setCountry}
                state={state}
                setState={setState}
                sector={sector}
                setSector={setSector}
                siteCountry={siteCountry}
                setSiteCountry={setSiteCountry}
                co2Emission={co2Emission}
                setCo2Emission={setCo2Emission}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                eu={eu}
                setEu={setEu}
                purchaseOptions={purchaseOptionsList}
                onSubmit={e => {
                  const form = e.target

                  e.preventDefault()

                  if (formState !== FORM_STATE_DEFAULT && formState !== FORM_STATE_ERROR) {
                    return false
                  }

                  setFormState(FORM_STATE_SENDING)
                  performPostRequest(getFormData(form, formType, country, state, sector, co2Emission, timeframe, eu ))
                    .then(() => setFormState(FORM_STATE_SUCCESS))
                    .catch(e => {
                      console.log(e)
                      console.error(e)
                      setFormState(FORM_STATE_ERROR)
                    })
                    .finally(() => form.reset())
                }}
                disabled={formState === FORM_STATE_SENDING}
              />
            )}
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}

const Form = ({ onSubmit, t, disabled, formType, setFormType, country, setCountry, state, setState, sector, setSector, siteCountry, setSiteCountry, co2Emission, setCo2Emission, timeframe, setTimeframe, eu, setEu, purchaseOptions }) => {
  return (
  <form
    method='POST'
    className='form-input'
    onSubmit={onSubmit}
  >
    <fieldset disabled={disabled}>
      <Row>
        <Col className='col-md-6 col-12'>
          <label>
            Category*

            <CustomDropdown
              options={['General', 'Commercial inquiries', 'Job opportunities', 'Media', 'Science inquiries', 'Visits']}
              onSelect={(value) => {
                setFormType(value)
              }}
              value={formType}
            />
          </label>
        </Col>
        <Col className='col-md-6 col-12'>
          <label className={formType === 'Job opportunities' && styles.disabled}>
            {t(`Contact us form|name label`)}

            <input
              name='name'
              type='text'
              required
              placeholder='First and last name'
            />
          </label>
        </Col>
      </Row>
      {formType === 'General' && 
        <Row>
          <Col className='col-md-6 col-12'>
            <label className={styles.checkBoxLabel}>
              We have an <a href="/faq" target="_blank" className={styles.link}>FAQ</a> section on our website
            </label>  
            <label className={styles.checkBox}>
              <input
                type='checkbox'
                required
                className='mr-1'
              />
              I have looked at the FAQ site
            </label>
          </Col>
        </Row>
      }
      {formType === 'Job opportunities' && 
        <Row>
          <Col className='col-md-6 col-12'>
            <label>
            For job opportunities, please see our <a href="https://jobs.50skills.com/carbfix/en" target="_blank" className={styles.link}>career site</a>
            </label>
          </Col>
        </Row>
      }
      {formType === 'Visits' && 
        <Row>
        <Col className='col-md-6 col-12'>
          <label className={styles.checkBoxLabel}>
          For visits, please see our <a href="/visit-us" target="_blank" className={styles.link}>Visit us - Carbfix</a>
          </label>  
          <label className={styles.checkBox}>
            <input
              type='checkbox'
              required
              className='mr-1'
            />
            I have looked at the Visit us site
          </label>
        </Col>
      </Row>
      }
      <Row>
        <Col className='col-md-6 col-12'>
          <label className={formType === 'Job opportunities' && styles.disabled}>
            {t(`Contact us form|email label`)}

            <input
              type='email'
              name='email'
              required
              placeholder='Email'
            />
          </label>
        </Col>
        <Col className='col-md-6 col-12'>
          <label className={formType === 'Job opportunities' && styles.disabled}>
            {t(`Contact us form|phone label`).substring(0, t(`Contact us form|phone label`).length - 1)}

            <input
              name='phone'
              type='text'
              inputMode='numeric'
              placeholder='Phone'
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col className='col-md-6 col-12'>
          <label className={formType === 'Job opportunities' && styles.disabled}>
            Company

            <input
              type='text'
              name='company'
              placeholder='Company'
            />
          </label>
        </Col>
        <Col className='col-md-6 col-12'>
          <label className={formType === 'Job opportunities' && styles.disabled}>
            Role

            <input
              type='text'
              name='role'
              placeholder='Role'
            />
          </label>
        </Col>
      </Row>
      {formType === 'Commercial inquiries' && 
        <>
          <Row>
            <Col className='col-md-6 col-12'>
              <label>
                Country*

                <CustomDropdown
                  options={countryList}
                  onSelect={(value) => {
                    setCountry(value)
                    if (!countryInEU(value)) {
                      setEu('Please select')
                    }
                  }}
                  value={country}
                  isSearchable={true}
                />
              </label>
            </Col>
            <Col className='col-md-6 col-12'>
              <label className={country === 'United States of America (the)' ? styles.stateUs : styles.stateNotUs}>
                State

                <CustomDropdown
                  options={states}
                  onSelect={(value) => {
                    setState(value)
                  }}
                  value={state}
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col className='col-md-6 col-12'>
              <label>
                Sector*

                <CustomDropdown
                  options={['Aluminum', 'Cement', 'Chemicals', 'Steel', 'BECCS', 'DAC', 'Geothermal','Project Developer', 'Other (please add text)']}
                  onSelect={(value) => {
                    setSector(value)
                  }}
                  value={sector}
                />
              </label>
            </Col>
            <Col className='col-md-6 col-12'>
              <label className={sector === 'Other (please add text)' ? styles.stateUs : styles.stateNotUs}>
                Sector other

                <input
                  type='text'
                  name='sector_other'
                  required={sector === 'Other (please add text)'}
                  placeholder='Sector other'
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col className='col-md-6 col-12'>
              <label>
                Plant site address

                <input
                  type='text'
                  name='plant_site_address'
                  required
                  placeholder='Streetname, Housenumber, City, Postcode, Country'
                />
              </label>
            </Col>
            <Col className='col-md-6 col-12'>
              <label>
                CO2 emission

                <CustomDropdown
                  options={['< 60.000 tons per year', '60 - 90.000 tons per year', '90 - 150.000 tons per year', '150 - 300.000 tons per year', '> 300.000 tons per year']}
                  onSelect={(value) => {
                    setCo2Emission(value)
                  }}
                  value={co2Emission}
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col className='col-md-6 col-12'>
              <label>
                Purchase timeframe

                <CustomDropdown
                  options={purchaseOptions}
                  onSelect={(value) => {
                    setTimeframe(value)
                  }}
                  value={timeframe}
                />
              </label>
            </Col>
            {countryInEU(country) && <Col className='col-md-6 col-12'>
              <label>
                EU ETS

                <CustomDropdown
                  options={['Yes', 'No', 'N/A']}
                  onSelect={(value) => {
                    setEu(value)
                  }}
                  value={eu}
                />
              </label>
            </Col>}
          </Row>
        </>
      }
      <label className={formType === 'Job opportunities' && styles.disabled}>
        {t(`Contact us form|message label`).includes('*') ? t(`Contact us form|message label`).substring(0, t(`Contact us form|message label`).length - 1) : t(`Contact us form|message label`)}
        <textarea
          name='message'
          type='textarea'
        />
      </label>

      <button
        disabled={disabled || formType === 'Job opportunities'}
        type='submit'
        className={cn('mt-3 button button--primary d-block m-0 w-100', formType === 'Job opportunities' && styles.disabled)}
      >
        {t`Contact us form|button label`}
      </button>
    </fieldset>
  </form>
)}

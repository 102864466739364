import React from 'react'
import styles from './MenuDesktop.module.scss'
import { Container, Row, Col } from '@kogk/common'
import PropTypes from 'prop-types'
import { Link } from '@kogk/gatsby-theme-base'
import { Arrow } from '@src/assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'
import cn from 'classnames'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { useLocation } from '@reach/router'
import { useGlobalData } from '@src/data/global-data'

const MenuDesktop = ({ items, title, altUrls }) => {
  const { t, language } = useLanguage()
  const altUrl = altUrls ? altUrls[0].url : ''
  const currlang = { title: t(`default|${language}`), url: altUrl }
  const location = useLocation()
  const { isSelfOrChild } = useGlobalData()

  return (
    <section className={styles.navigation} aria-label={title} title={title}>
      <Container>
        <Row>
          <Col>
            <ul className='d-flex'>
              {items.map((item, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    item={item}
                    isActive={isSelfOrChild(location.pathname, item)}
                  >
                    {item.subMenu.length > 0 && (
                      <Submenu items={item.subMenu} />
                    )}
                  </MenuItem>
                )
              })}
              {altUrls && altUrls.length > 1 && (
                <li
                  aria-label='change language'
                  className={cn(
                    'pl-3 pr-2 button-no-style',
                    styles.languageButton
                  )}
                >
                  <FontAwesomeIcon icon={faGlobe} />
                  <div className={styles.submenu}>
                    <ul className='mt-2'>
                      <li>
                        <Link
                          role="button"
                          activeClassName={styles.menuItemActive}
                          className={cn('d-flex justify-content-center', styles.menuItem)}
                          onClick={() => window.location.href = currlang.url}
                        >
                          {t(`default|${language}`)}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MenuDesktop

const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    subMenu: PropTypes.array
  })
).isRequired

MenuDesktop.propTypes = {
  items: itemsPropType,
  title: PropTypes.string
}

const Submenu = ({ items, className = styles.submenu }) => {
  const { isSelfOrChild } = useGlobalData()
  const location = useLocation()
  return (
    <div className={className}>
      <ul>
        {items.map((item, idx) => {
          return (
            <MenuItem
              key={idx}
              item={item}
              isSubmenu={true}
              isActive={isSelfOrChild(location.pathname, item)}
            >
              {item.subMenu.length > 0 && (
                <Submenu className={styles.subsubmenu} items={item.subMenu} />
              )}
            </MenuItem>
          )
        })}
      </ul>
    </div>
  )
}

const MenuItem = ({ item, children, isSubmenu, isActive }) => (
  <li key={item.url}>
    <Link
      to={item.url}
      activeClassName={cn({ [styles.menuItemActive]: isActive })}
      className={cn('d-flex justify-content-center', styles.menuItem, {
        [styles.menuItemActive]: isActive
      })}
    >
      {!isSubmenu && <span className={styles.line} />}

      {item.title}

      {item.subMenu.length > 0 && <Arrow />}
    </Link>
    {children}
  </li>
)

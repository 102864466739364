import React from 'react'
import { Footer, NavBar } from '@cmp/site'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { useLocation } from '@reach/router'
import BaseLayout from './BaseLayout'
import styles from './MainLayout.module.scss'

export default ({ children, altUrls, hasEuText, hasHeroImage }) => {
  const { t } = useLanguage()
  const { pathname } = useLocation()
  const isFrontpage = pathname === '/'

  return (
    <BaseLayout>

      <a href='#main-content' className={styles.skipToMain}>
        {t('jump to content')}
      </a>

      <NavBar altUrls={altUrls} />

      <main id='main-content' className={hasHeroImage ? styles.mainHero : styles.main}>
        {children}
      </main>

      <Footer
        className={styles.footer}
        isFrontpage={isFrontpage}
        hasEuText={hasEuText}
      />
    </BaseLayout>
  )
}

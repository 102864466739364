import React from 'react'

export default ({ props }) => {
  return (
    <svg
      width='81'
      height='80'
      viewBox='0 0 81 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_338_294)'>
        <path
          d='M23 18.75C23 17.5139 23.3666 16.3055 24.0533 15.2777C24.7401 14.2499 25.7162 13.4488 26.8582 12.9758C28.0003 12.5027 29.2569 12.3789 30.4693 12.6201C31.6817 12.8613 32.7953 13.4565 33.6694 14.3306C34.5435 15.2047 35.1387 16.3183 35.3799 17.5307C35.6211 18.7431 35.4973 19.9997 35.0242 21.1418C34.5512 22.2838 33.7501 23.2599 32.7223 23.9467C31.6945 24.6334 30.4861 25 29.25 25'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.25 32.5C29.0028 32.5 28.7611 32.5733 28.5555 32.7107C28.35 32.848 28.1898 33.0432 28.0952 33.2716C28.0005 33.5001 27.9758 33.7514 28.024 33.9939C28.0723 34.2363 28.1913 34.4591 28.3661 34.6339C28.5409 34.8087 28.7637 34.9277 29.0061 34.976C29.2486 35.0242 29.4999 34.9995 29.7284 34.9048C29.9568 34.8102 30.152 34.65 30.2893 34.4445C30.4267 34.2389 30.5 33.9972 30.5 33.75C30.5 33.4185 30.3683 33.1005 30.1339 32.8661C29.8995 32.6317 29.5815 32.5 29.25 32.5Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58 7.5V22.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53 2.5C54.3261 2.5 55.5978 3.02678 56.5355 3.96447C57.4732 4.90215 58 6.17392 58 7.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 2.5H53'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 7.5C3 6.17392 3.52678 4.90215 4.46447 3.96447C5.40215 3.02678 6.67392 2.5 8 2.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 42.5V7.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 47.5C6.67392 47.5 5.40215 46.9732 4.46447 46.0355C3.52678 45.0979 3 43.8261 3 42.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18 47.5H8'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18 62.5V47.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28 55L18 62.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78 62.5H68V77.5L53 62.5H38V32.5H78V62.5Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58 42.5V52.5'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_338_294'>
          <rect
            width='80'
            height='80'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

import React from 'react'
import {
  Row,
  Col,
  Container,
  RawHtml,
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import { Link } from "gatsby"
import cn from 'classnames'

import styles from './ContactCardSection.module.scss'
import { LongArrowRight } from '@src/assets/index'

export default ({ html, cardText, linkText, linkUrl }) => {
  
  return (
    <SliceWrapper textCenter={false}>
    <Container className={cn(styles.allContainer)} >
      <RawHtml el='div' className={cn('pb-3', styles.title)}>
        {html}
      </RawHtml>
      <Row className={styles.rowContainer}>
            <Col className={cn('col-12', styles.card)}>
              <Link to={linkUrl ? linkUrl : '/'} className={styles.cardLink}>
                <div className={styles.content}>
                  <RawHtml el='div'>{cardText}</RawHtml>
                </div>
                <div className={styles.link}>
                  {linkText ? linkText.toUpperCase() : ''}
                  <LongArrowRight />
                </div>
              </Link>
            </Col>
      </Row>
    </Container>
    </SliceWrapper>
  )
}

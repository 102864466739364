import React from 'react'

/* eslint max-len: ['error', {'code': 15000}] */

const SvgComponent = () => (
  <svg width='3002px' height='61px' viewBox='0 0 3002 61' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <title>Carbfix-svg-BG</title>
    <g id='Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.5'>
      <g id='Carbfix-svg-BG' transform='translate(1.000000, 3.000000)' stroke='#FFFFFF' strokeWidth='5'>
        <g>
          <path d='M0,33.5521236 L29.8588831,26.5994566 L74.1642383,49.0540541 L101.955312,37.8267553 L145.57622,49.0540541 L197.331412,55 L240.97076,55 C253.329125,51.036036 259.508307,49.0540541 259.508307,49.0540541 C259.508307,49.0540541 269.532123,51.036036 289.579753,55 L364.991374,55 L385.900212,49.0540541 L394.365516,37.8267553 L422.408442,33.5521236 L468.178649,49.0540541 L503.972442,49.0540541 C523.270799,38.7194337 532.919978,33.5521236 532.919978,33.5521236 C532.919978,33.5521236 532.919978,28.0754564 532.919978,17.1221221 L554.114728,8.65937366 L583.403601,0 C602.670066,0.131083464 613.382705,3.01754135 615.541515,8.65937366 C617.700326,14.301206 622.30968,20.5814147 629.369576,27.5 L658.762286,43.4423709 L725.819174,43.4423709 L721.078962,27.5 C742.28411,20.5814147 757.196478,17.1221221 765.816063,17.1221221 C778.74544,17.1221221 851.69981,29.2774918 851.69981,33.5521236 C851.69981,36.4018781 861.452059,30.9252109 880.956558,17.1221221 L934.171272,33.5521236 L990.477771,43.4423709 C1024.70108,36.8488727 1044.32912,33.5521236 1049.36188,33.5521236 C1054.39463,33.5521236 1082.36328,36.8488727 1133.26782,43.4423709 L1176.52979,37.8267553 L1232.31753,43.4423709 L1255.37996,49.0540541 L1274.62349,43.4423709 C1301.60761,35.9548835 1315.09967,34.0830116 1315.09967,37.8267553 C1315.09967,41.5704991 1331.45804,41.5704991 1364.17479,37.8267553 L1364.17479,26.5994566 C1359.38789,25.9230659 1359.38789,22.7639544 1364.17479,17.1221221 C1371.35514,8.65937366 1410.66971,8.65937366 1410.66971,8.65937366 C1410.66971,8.65937366 1459.96585,25.6989132 1459.96585,26.5994566 C1459.96585,27.1998189 1473.31056,30.9422518 1500,37.8267553' id='Path-2-Copy-3' transform='translate(750.000000, 27.500000) scale(1, -1) translate(-750.000000, -27.500000) ' />
          <path d='M1500,37.8267553 L1529.85888,26.5994566 L1574.16424,49.0540541 L1601.95531,37.8267553 L1645.57622,49.0540541 L1697.33141,55 L1740.97076,55 C1753.32912,51.036036 1759.50831,49.0540541 1759.50831,49.0540541 C1759.50831,49.0540541 1769.53212,51.036036 1789.57975,55 L1864.99137,55 L1885.90021,49.0540541 L1894.36552,37.8267553 L1922.40844,33.5521236 L1968.17865,49.0540541 L2003.97244,49.0540541 C2023.2708,38.7194337 2032.91998,33.5521236 2032.91998,33.5521236 C2032.91998,33.5521236 2032.91998,28.0754564 2032.91998,17.1221221 L2054.11473,8.65937366 L2083.4036,0 C2102.67007,0.131083464 2113.3827,3.01754135 2115.54152,8.65937366 C2117.70033,14.301206 2122.30968,20.5814147 2129.36958,27.5 L2158.76229,43.4423709 L2225.81917,43.4423709 L2221.07896,27.5 C2242.28411,20.5814147 2257.19648,17.1221221 2265.81606,17.1221221 C2278.74544,17.1221221 2351.69981,29.2774918 2351.69981,33.5521236 C2351.69981,36.4018781 2361.45206,30.9252109 2380.95656,17.1221221 L2434.17127,33.5521236 L2490.47777,43.4423709 C2524.70108,36.8488727 2544.32912,33.5521236 2549.36188,33.5521236 C2554.39463,33.5521236 2582.36328,36.8488727 2633.26782,43.4423709 L2676.52979,37.8267553 L2732.31753,43.4423709 L2755.37996,49.0540541 L2774.62349,43.4423709 C2801.60761,35.9548835 2815.09967,34.0830116 2815.09967,37.8267553 C2815.09967,41.5704991 2831.45804,41.5704991 2864.17479,37.8267553 L2864.17479,26.5994566 C2859.38789,25.9230659 2859.38789,22.7639544 2864.17479,17.1221221 C2871.35514,8.65937366 2910.66971,8.65937366 2910.66971,8.65937366 C2910.66971,8.65937366 2959.96585,25.6989132 2959.96585,26.5994566 C2959.96585,27.1998189 2973.31056,32.367129 3000,42.1013871' id='Path-2-Copy-6' transform='translate(2250.000000, 27.500000) scale(1, -1) translate(-2250.000000, -27.500000) ' />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent


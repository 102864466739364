import React from 'react'

export default ({ props }) => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M68.9667 46.5335C72.5749 46.5335 75.5 43.6084 75.5 40.0001C75.5 36.3919 72.5749 33.4668 68.9667 33.4668C65.3584 33.4668 62.4333 36.3919 62.4333 40.0001C62.4333 43.6084 65.3584 46.5335 68.9667 46.5335Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M34.7333 44.8996C37.4395 44.8996 39.6333 42.7058 39.6333 39.9996C39.6333 37.2934 37.4395 35.0996 34.7333 35.0996C32.0271 35.0996 29.8333 37.2934 29.8333 39.9996C29.8333 42.7058 32.0271 44.8996 34.7333 44.8996Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20 42.5001C19.5086 42.487 19.032 42.3293 18.6297 42.0467C18.2274 41.7642 17.9174 41.3693 17.7384 40.9115C17.5593 40.4537 17.5192 39.9533 17.6231 39.4728C17.727 38.9923 17.9702 38.5531 18.3223 38.2101C18.6745 37.8671 19.1199 37.6355 19.6029 37.5444C20.086 37.4532 20.5852 37.5064 21.0381 37.6974C21.4911 37.8885 21.8777 38.2088 22.1495 38.6183C22.4214 39.0279 22.5665 39.5085 22.5667 40.0001C22.5667 40.6631 22.3033 41.299 21.8344 41.7679C21.3656 42.2367 20.7297 42.5001 20.0667 42.5001'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7 42.5C6.50555 42.5 6.0222 42.3534 5.61108 42.0787C5.19995 41.804 4.87952 41.4135 4.6903 40.9567C4.50108 40.4999 4.45157 39.9972 4.54804 39.5123C4.6445 39.0273 4.8826 38.5819 5.23223 38.2322C5.58187 37.8826 6.02732 37.6445 6.51228 37.548C6.99723 37.4516 7.49989 37.5011 7.95671 37.6903C8.41352 37.8795 8.80397 38.2 9.07868 38.6111C9.35338 39.0222 9.5 39.5055 9.5 40C9.5 40.663 9.23661 41.2989 8.76777 41.7678C8.29893 42.2366 7.66304 42.5 7 42.5'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M34.7333 61.2004C37.4395 61.2004 39.6333 59.0066 39.6333 56.3004C39.6333 53.5942 37.4395 51.4004 34.7333 51.4004C32.0271 51.4004 29.8333 53.5942 29.8333 56.3004C29.8333 59.0066 32.0271 61.2004 34.7333 61.2004Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20 58.7998C19.5055 58.7998 19.0222 58.6532 18.6111 58.3785C18.2 58.1038 17.8795 57.7133 17.6903 57.2565C17.5011 56.7997 17.4516 56.297 17.548 55.8121C17.6445 55.3271 17.8826 54.8817 18.2322 54.532C18.5819 54.1824 19.0273 53.9443 19.5123 53.8478C19.9972 53.7514 20.4999 53.8009 20.9567 53.9901C21.4135 54.1793 21.804 54.4998 22.0787 54.9109C22.3534 55.322 22.5 55.8054 22.5 56.2998C22.5 56.9628 22.2366 57.5987 21.7678 58.0676C21.2989 58.5364 20.663 58.7998 20 58.7998Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M34.7333 28.5998C37.4395 28.5998 39.6333 26.406 39.6333 23.6998C39.6333 20.9936 37.4395 18.7998 34.7333 18.7998C32.0271 18.7998 29.8333 20.9936 29.8333 23.6998C29.8333 26.406 32.0271 28.5998 34.7333 28.5998Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M51.0333 44.8996C53.7395 44.8996 55.9333 42.7058 55.9333 39.9996C55.9333 37.2934 53.7395 35.0996 51.0333 35.0996C48.3271 35.0996 46.1333 37.2934 46.1333 39.9996C46.1333 42.7058 48.3271 44.8996 51.0333 44.8996Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M51.0333 61.2004C53.7395 61.2004 55.9333 59.0066 55.9333 56.3004C55.9333 53.5942 53.7395 51.4004 51.0333 51.4004C48.3271 51.4004 46.1333 53.5942 46.1333 56.3004C46.1333 59.0066 48.3271 61.2004 51.0333 61.2004Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M51.0333 28.5998C53.7395 28.5998 55.9333 26.406 55.9333 23.6998C55.9333 20.9936 53.7395 18.7998 51.0333 18.7998C48.3271 18.7998 46.1333 20.9936 46.1333 23.6998C46.1333 26.406 48.3271 28.5998 51.0333 28.5998Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M34.7333 12.3C37.4395 12.3 39.6333 10.1062 39.6333 7.4C39.6333 4.6938 37.4395 2.5 34.7333 2.5C32.0271 2.5 29.8333 4.6938 29.8333 7.4C29.8333 10.1062 32.0271 12.3 34.7333 12.3Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M34.7333 77.5002C37.4395 77.5002 39.6333 75.3064 39.6333 72.6002C39.6333 69.894 37.4395 67.7002 34.7333 67.7002C32.0271 67.7002 29.8333 69.894 29.8333 72.6002C29.8333 75.3064 32.0271 77.5002 34.7333 77.5002Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20 26.2002C19.5055 26.2002 19.0222 26.0536 18.6111 25.7789C18.2 25.5042 17.8795 25.1137 17.6903 24.6569C17.5011 24.2001 17.4516 23.6974 17.548 23.2125C17.6445 22.7275 17.8826 22.2821 18.2322 21.9324C18.5819 21.5828 19.0273 21.3447 19.5123 21.2482C19.9972 21.1518 20.4999 21.2013 20.9567 21.3905C21.4135 21.5797 21.804 21.9001 22.0787 22.3113C22.3534 22.7224 22.5 23.2057 22.5 23.7002C22.5 24.3632 22.2366 24.9991 21.7678 25.468C21.2989 25.9368 20.663 26.2002 20 26.2002'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

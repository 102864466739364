import React from "react";
import { Container } from "@kogk/common";
import { SliceWrapper } from "@cmp/site";
import cn from "classnames";

import styles from "./IframeSection.module.scss";

export default ({ url }) => {
  if (!url) return null;
  return (
    <SliceWrapper textCenter={false}>
      <Container>
        <iframe src={url} frameborder="0" width="100%" height="100%"></iframe>
      </Container>
    </SliceWrapper>
  );
};

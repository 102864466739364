import React, { useRef, useEffect, useState } from 'react'
import CarbfixBGLine from '../../../assets/svgs/CarbfixBGLine.js'
import CarbfixMobileBGLine from '../../../assets/svgs/FaultlinesMobile.js'
import NedstaLina from '../../../assets/svgs/NedstaLina.js'
import CarbfixLottiHero from '../../../assets/lotties/CarbfixLottiHero.json'
import CarbfixLottiHeroIsl from '../../../assets/lotties/CarbfixLottiHeroIsl.json'
import CarbfixLottiHeroMobile from '../../../assets/lotties/CarbfixLottiHeroMobile.json'
import CarbfixLottiHeroMobileIsl from '../../../assets/lotties/CarbfixLottiHeroMobileIsl.json'
import styles from './FrontpageImage.module.scss'
import { RichText } from '@src/components/sections'
import { FancyShow } from '@kogk/common'
import cn from 'classnames'
import { useGlobalData } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default ({ text }) => {
  const { isMobile } = useGlobalData()
  const animBox = useRef()
  const [lottie, setLottie] = useState(null);

  const { language } = useLanguage()
  const localeLottie = language === 'en'
  ? { desktop: CarbfixLottiHero, mobile: CarbfixLottiHeroMobile }
  : { desktop: CarbfixLottiHeroIsl, mobile: CarbfixLottiHeroMobileIsl }

  const Lottie = isMobile ? localeLottie.mobile : localeLottie.desktop

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, [])
  
  useEffect(() => {
    if (lottie && animBox.current) {
      const animation = lottie.loadAnimation({
        container: animBox.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: Lottie,
        useHardwareAcceleration: true
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <FancyShow>
    <div className={styles.text}>
      <RichText html={text} />
    </div>
    <div className={cn(
      'mb-0 mb-md-5 mb-lg-6 mb-xl-5 mb-xxl-3',
      styles.lottieContainer
    )}>
      <div ref={animBox} className={styles.animationContainer} />
      <div className={styles.counterWrapper}>
        <Counter />
      </div>
      <div className={styles.line}>
        <CarbfixBGLine />
      </div>
      <div className={styles.lineMobile}>
        <CarbfixMobileBGLine />
      </div>
    </div>
    <div className={styles.counterWrapperMobile}>
      <Counter />
    </div>
    <div className='d-none d-lg-block overflow-hidden pb-4'>
      <NedstaLina />
    </div>

  </FancyShow>
}

const Counter = (target) => {
  const { language, t } = useLanguage()
  const [counter, setCounter] = useState(0)
  const [counter2, setCounter2] = useState(0)
  const localeString = language === 'en' ? 'en-US' : 'de-DE'

  function getSecondsSince2015() {
    var d = new Date()
    var e = new Date(2020, 0, 1)
    var since2015 = d - e

    return since2015 / 1000
  }

  const getSecondsSinceMidnight = () => {
    var d = new Date()
    var e = new Date(d)
    var msSinceMidnight = e - d.setHours(0, 0, 0, 0)

    return msSinceMidnight / 1000
  }

  var divider = 4
  var increment = 0.000377 / divider
  var INTERVAL = 1000 / divider
  let val1
  let val2

  const getVal1 = () => {
    var sinceMidnight = getSecondsSinceMidnight()
    var value = (sinceMidnight * increment * divider)
      .toLocaleString(localeString, { minimumFractionDigits: 1, maximumFractionDigits: 1 })

    return value
  }

  const getVal2 = () => {
    var since2015 = getSecondsSince2015()
    var value = ((since2015 * increment * divider) + 51500)
      .toLocaleString(localeString, {minimumFractionDigits: 1, maximumFractionDigits: 1})

    return value
  }

  val1 = getVal1()
  val2 = getVal2()

  setInterval(function () {
    val1 = getVal1()
    val2 = getVal2()

    setCounter(val1)
    setCounter2(val2)
  }, INTERVAL)

  return (
    <>
      <div className={styles.counter}>{counter}</div>
      <p className="parag-big mb-0">{t`frontpagehero|today`}</p>
      <p className="parag-small mb-2">{t`frontpagehero|unit`}</p>

      <div className={styles.counter}>
        {counter2}
        {language === "en" && "*"}
      </div>
      <p className="parag-big mb-0">{t`frontpagehero|since 2014`}</p>
      <p className={cn(styles.unit, "parag-small")}>{t`frontpagehero|unit`}</p>
      {language === "en" && (
        <p
          className={cn(styles.clarification, "parag-smaller")}
        >{t`frontpagehero|clarification`}</p>
      )}
    </>
  );
}

import React from 'react'

export default ({ props }) => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.9967 67.5C19.8292 67.5 23.7467 63.5825 23.7467 58.75C23.7467 53.9175 19.8292 50 14.9967 50C10.1642 50 6.24667 53.9175 6.24667 58.75C6.24667 63.5825 10.1642 67.5 14.9967 67.5Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M27.4967 77.5C26.2884 75.2345 24.4871 73.3399 22.2854 72.0189C20.0836 70.6978 17.5643 70 14.9967 70C12.4291 70 9.9097 70.6978 7.70798 72.0189C5.50627 73.3399 3.70497 75.2345 2.49667 77.5'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M64.9967 67.5C69.8292 67.5 73.7467 63.5825 73.7467 58.75C73.7467 53.9175 69.8292 50 64.9967 50C60.1642 50 56.2467 53.9175 56.2467 58.75C56.2467 63.5825 60.1642 67.5 64.9967 67.5Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M77.4967 77.5C76.2884 75.2345 74.4871 73.3399 72.2854 72.0189C70.0837 70.6978 67.5643 70 64.9967 70C62.4291 70 59.9097 70.6978 57.708 72.0189C55.5063 73.3399 53.705 75.2345 52.4967 77.5'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M39.9967 20C44.8292 20 48.7467 16.0825 48.7467 11.25C48.7467 6.41751 44.8292 2.5 39.9967 2.5C35.1642 2.5 31.2467 6.41751 31.2467 11.25C31.2467 16.0825 35.1642 20 39.9967 20Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M50.8267 27.5004C49.4908 25.9319 47.8302 24.6722 45.9598 23.8083C44.0893 22.9445 42.0536 22.4971 39.9933 22.4971C37.933 22.4971 35.8973 22.9445 34.0269 23.8083C32.1564 24.6722 30.4959 25.9319 29.16 27.5004'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M30.1667 65.6899C36.5507 68.1238 43.6108 68.1024 49.98 65.6299'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M21.16 20C18.4235 22.563 16.2423 25.6605 14.7515 29.1008C13.2607 32.541 12.4921 36.2507 12.4933 40C12.4933 40.8433 12.5433 41.6667 12.62 42.5'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M67.37 42.5C67.4434 41.6733 67.4967 40.8333 67.4967 40C67.4985 36.2506 66.7302 32.5407 65.2394 29.1004C63.7486 25.6601 61.567 22.5626 58.83 20'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

import React from 'react'
import {
  Row,
  Col,
  Container,
  RawHtml,
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import { Link } from "gatsby"
import cn from 'classnames'

import styles from './ServiceStepsSection.module.scss'
import { LongArrowRight } from '@src/assets/index'

export default ({ html, items }) => {
  const col = items ? (12 / items.length) : 4
  
  return (
    <SliceWrapper textCenter={false}>
    <Container className={cn(styles.allContainer)} >
      <RawHtml el='div' className='pb-3'>
        {html}
      </RawHtml>
      <Row className={styles.rowContainer}>
        {items ? items.map((item, index) => { 
          return (
            <Col key={index} className={`col-md-${col} ${styles.card}`}>
              <Link to={item.link.url ?? '/'} className={styles.cardLink}>
                <div className={cn(styles.content, {'pb-2': !item.link_text })}>
                  <RawHtml el='div'>{item.step_text.html}</RawHtml>
                </div>
                {item.link_text ? <div className={styles.link}>
                  {item.link_text?.toUpperCase() ?? ''}
                  <LongArrowRight />
                </div> : null}
              </Link>
            </Col>
          )}
        ) : null}
      </Row>
    </Container>
    </SliceWrapper>
  )
}

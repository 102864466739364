import React from 'react'
import BaseLayout from './BaseLayout'
import styles from './AnimationLayout.module.scss'

export default ({ children }) => {
  return (
    <BaseLayout>
      <main id='main-content' className={styles.main}>
        {children}
      </main>
    </BaseLayout>
  )
}

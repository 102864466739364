import React from 'react'

/* eslint max-len: ['error', {'code': 15000}] */

const SvgComponent = () => (
  <svg width='502px' height='132px' viewBox='0 0 502 132' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g id='Mobile' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Fault-lines-mobile' transform='translate(1.000000, 0.500000)'>
        <path d='M0,116.46139 L9.95296105,111.910553 L24.7214128,126.608108 L33.985104,119.259331 L48.5254066,126.608108 L65.7771374,130.5 L80.3235866,130.5 C84.4430416,127.905405 86.5027691,126.608108 86.5027691,126.608108 C86.5027691,126.608108 89.8440409,127.905405 96.5265844,130.5 L121.663791,130.5 L128.633404,126.608108 L131.455172,119.259331 L140.802814,116.46139 L156.05955,126.608108 L167.990814,126.608108 C174.4236,119.843629 177.639993,116.46139 177.639993,116.46139 C177.639993,116.46139 177.639993,112.876662 177.639993,105.707207 L184.704909,100.167954 L194.467867,94.5 C200.890022,94.5858001 204.460902,96.475118 205.180505,100.167954 C205.900109,103.860789 207.43656,107.971471 209.789859,112.5 L219.587429,122.935006 L241.939725,122.935006 L240.359654,112.5 C247.428037,107.971471 252.398826,105.707207 255.272021,105.707207 C259.581813,105.707207 283.899937,113.663449 283.899937,116.46139 C283.899937,118.326684 287.150686,114.741956 293.652186,105.707207 L311.390424,116.46139 L330.159257,122.935006 C341.567028,118.619262 348.109707,116.46139 349.787292,116.46139 C351.464877,116.46139 360.78776,118.619262 377.75594,122.935006 L392.176596,119.259331 L410.77251,122.935006 L418.459986,126.608108 L424.874497,122.935006 C433.869203,118.034106 438.366557,116.80888 438.366557,119.259331 C438.366557,121.709781 443.819348,121.709781 454.72493,119.259331 L454.72493,111.910553 C453.129297,111.467825 453.129297,109.400043 454.72493,105.707207 C457.118378,100.167954 470.223238,100.167954 470.223238,100.167954 C470.223238,100.167954 486.655282,111.321107 486.655282,111.910553 C486.655282,112.303518 491.103521,114.75311 500,119.259331' id='Path-2-Copy' stroke='#FFFFFF' opacity='0.049999997'></path>
        <path d='M0,50.46139 L9.95296105,45.9105534 L24.7214128,60.6081081 L33.985104,53.2593308 L48.5254066,60.6081081 L65.7771374,64.5 L80.3235866,64.5 C84.4430416,61.9054054 86.5027691,60.6081081 86.5027691,60.6081081 C86.5027691,60.6081081 89.8440409,61.9054054 96.5265844,64.5 L121.663791,64.5 L128.633404,60.6081081 L131.455172,53.2593308 L140.802814,50.46139 L156.05955,60.6081081 L167.990814,60.6081081 C174.4236,53.8436293 177.639993,50.46139 177.639993,50.46139 C177.639993,50.46139 177.639993,46.8766624 177.639993,39.7072072 L184.704909,34.1679537 L194.467867,28.5 C200.890022,28.5858001 204.460902,30.475118 205.180505,34.1679537 C205.900109,37.8607894 207.43656,41.9714715 209.789859,46.5 L219.587429,56.9350064 L241.939725,56.9350064 L240.359654,46.5 C247.428037,41.9714715 252.398826,39.7072072 255.272021,39.7072072 C259.581813,39.7072072 283.899937,47.6634492 283.899937,50.46139 C283.899937,52.3266838 287.150686,48.7419562 293.652186,39.7072072 L311.390424,50.46139 L330.159257,56.9350064 C341.567028,52.6192621 348.109707,50.46139 349.787292,50.46139 C351.464877,50.46139 360.78776,52.6192621 377.75594,56.9350064 L392.176596,53.2593308 L410.77251,56.9350064 L418.459986,60.6081081 L424.874497,56.9350064 C433.869203,52.0341055 438.366557,50.8088803 438.366557,53.2593308 C438.366557,55.7097812 443.819348,55.7097812 454.72493,53.2593308 L454.72493,45.9105534 C460.299317,49.9963535 463.884326,50.1928357 465.479959,46.5 C467.873407,40.9607465 474.070411,56.9350064 474.070411,56.9350064 C474.070411,56.9350064 486.655282,45.3211068 486.655282,45.9105534 C486.655282,46.3035178 491.103521,48.7531103 500,53.2593308' id='Path-2-Copy-2' stroke='#FFFFFF' opacity='0.049999997'></path>
        <rect id='Rectangle' fill='#FFFFFF' x='0' y='0.5' width='500' height='2'></rect>
      </g>
    </g>
  </svg>
)

export default SvgComponent

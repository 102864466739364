import React, { useState } from "react";
import { Container } from "@kogk/common";
import { SliceWrapper } from "@cmp/site";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/pro-light-svg-icons";

import styles from "./ImageComparison.module.scss";

export default ({ aspect_ratio, left_image, right_image }) => {
  if (!left_image?.url || !right_image.url) return null;

  const [split, setSplit] = useState(50);

  return (
    <SliceWrapper textCenter={false}>
      <Container>
        <div
          className={styles.relativeContainer}
          style={{ aspectRatio: aspect_ratio ?? "16/9" }}
        >
          <div
            className={styles.imgContainer}
            style={{
              clipPath: `polygon(0% 0, 0% 100%, ${split}% 100%, ${split}% 0%)`,
            }}
          >
            <img src={left_image.url} alt={left_image?.alt ?? ""} />
            {left_image?.alt && (
              <span className={styles.imgCaption} style={{ left: "1rem" }}>
                {left_image.alt}
              </span>
            )}
          </div>
          <div
            className={styles.imgContainer}
            style={{
              clipPath: `polygon(${split}% 0, ${split}% 100%, 100% 100%, 100% 0%)`,
            }}
          >
            <img src={right_image.url} alt={right_image?.alt ?? ""} />
            {right_image?.alt && (
              <span className={styles.imgCaption} style={{ right: "1rem" }}>
                {right_image.alt}
              </span>
            )}
          </div>
          <span className={styles.sliderLabel} style={{ left: `${split}%` }}>
            <FontAwesomeIcon icon={faHandPointUp} />
          </span>
          <span className={styles.sliderLine} style={{ left: `${split}%` }} />
          <input
            className={styles.slider}
            type="range"
            min="0"
            max="100"
            value={split}
            onChange={(e) => {
              setSplit(e.target.value);
            }}
          />
        </div>
      </Container>
    </SliceWrapper>
  );
};

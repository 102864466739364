import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow,
  RawHtml
} from '@kogk/common'
import styles from './Embed.module.scss'

export default ({ html }) => {
  return (
    <FancyShow className="mb-4">
      <Container>
        <Row>
          <Col
            className='py-2'
            col={{ lg: 12 }}
          >
            <div className={styles.rawHtml}>
              <RawHtml el='div'>{html}</RawHtml>
            </div>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}

import React from 'react'
import {
  Row,
  Col,
  Container,
  RawHtml,
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import { wrapPTags } from '@src/utils/index'

import styles from './SalesCardSection.module.scss'
import cn from 'classnames'

export default ({ html, items }) => {
  const newItems = items ? items.map((item) => {
    return {
      ...item,
      card_text: {
        html: wrapPTags(item.card_text.html)
      }
    }
  }) : null

  const col = newItems ? (12 / newItems.length) : 4
  
  return (
    <SliceWrapper textCenter={false}>
    <Container className={cn('pt-4', styles.allContainer)} >
      <RawHtml el='div' className='pb-3'>
        {html}
      </RawHtml>
      <Row className={styles.rowContainer}>
        {newItems ? newItems.map((link, index) => { 
          return (
              <Col key={index} className={`col-md-${col} ${styles.card}`}>
                <RawHtml el='div' className={styles.title}>
                  {link.card_title.html}
                </RawHtml>
                <div className={styles.content}>
                {link.card_text.html.map((item, key) => {return (<div className='mb-2' key={key}><RawHtml el='div'>{item}</RawHtml></div>)})}
                </div>
              </Col>)}
        ) : null}
      </Row>
    </Container>
    </SliceWrapper>
  )
}

import React from 'react'

export default ({ props }) => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M77.5 20V67.5C77.5 69.4891 76.7098 71.3968 75.3033 72.8033C73.8968 74.2098 71.9891 75 70 75C68.0109 75 66.1032 74.2098 64.6967 72.8033C63.2902 71.3968 62.5 69.4891 62.5 67.5V10C62.5 8.67392 61.9732 7.40215 61.0355 6.46447C60.0978 5.52678 58.8261 5 57.5 5H7.5C6.17392 5 4.90215 5.52678 3.96447 6.46447C3.02678 7.40215 2.5 8.67392 2.5 10V67.5C2.5 69.4891 3.29018 71.3968 4.6967 72.8033C6.10322 74.2098 8.01088 75 10 75H70'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 50H50'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15 60H35'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M47.5 17.5H17.5C16.1193 17.5 15 18.6193 15 20V35C15 36.3807 16.1193 37.5 17.5 37.5H47.5C48.8807 37.5 50 36.3807 50 35V20C50 18.6193 48.8807 17.5 47.5 17.5Z'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

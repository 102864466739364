import React from 'react'
import {
  SearchResults,
  MapEmbed
} from '@cmp/sections'

export default ({ programName, options }) => {
  if (programName === 'search') {
    return <SearchResults />
  } if (programName === 'mapembed') {
    return <MapEmbed link={options} />
  } else {
    return <div>unhandled {programName}</div>
  }
}

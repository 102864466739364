import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import cn from 'classnames'
import styles from './SearchModal.module.scss'
import { SearchField } from '@src/components/sections'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default ({ isOpen, children }) => {
  const { t } = useLanguage()

  return (
    <div className={cn(
      { [styles.open]: isOpen },
      styles.wrapper
    )}>
      <Container>
        <Row>
          <Col
            col={11}
            offset={1}
            className='pt-5 d-flex justify-content-between'
          >
            <h2>{t(`Search strings|Enter Search label`)}</h2>
            {children}
          </Col>
        </Row>
        <Row>
          <Col col={11} offset={{ md: 1 }}>
            <SearchField />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React from 'react'
import { RawHtml } from '@kogk/common'
import { SliceWrapper } from '@cmp/site'

export default ({ html, textAlign }) => {
  return (
  <SliceWrapper textCenter={textAlign ?? true}>
    <RawHtml el='div' className='article'>
      {html}
    </RawHtml>
  </SliceWrapper>
)}

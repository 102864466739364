import React, { forwardRef } from 'react'
import { Container, Row, Col } from '@kogk/common'

export default forwardRef(({ children, ...rest }, ref) => {
  return (
    <Container className='py-2'>
      <Row>
        <Col ref={ref} offset={{ lg: 2 }} col={{ lg: 8 }} {...rest}>
          {children}
        </Col>
      </Row>
    </Container>
  )
})

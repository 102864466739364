import React, { useState } from 'react'
import styles from './MenuMobile.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { Arrow } from '@src/assets'
import cn from 'classnames'
import { SearchField } from '@src/components/sections'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default ({ items, isActive, altUrls }) => {
  const { t, language } = useLanguage()
  const altUrl = altUrls ? altUrls[0].url : ''
  const currlang = [{ title: t(`default|${language}`), url: altUrl }]

  return (
    <div className={cn(styles.wrapper, { [styles.closed]: !isActive })}>
      <SearchField />

      {altUrls && altUrls.length > 1 && (
        <span className={styles.languageBtn}>
          <MenuItem item={t(`default|language`)} hasSubmenu languageBtn>
            <Submenu items={currlang} />
          </MenuItem>
        </span>
      )}

      {items &&
        items.map(item => {
          const hasSubmenu = item.subMenu.length > 0
          return (
            <MenuItem key={item.title} item={item} hasSubmenu={hasSubmenu}>
              {hasSubmenu && <Submenu items={item.subMenu} />}
            </MenuItem>
          )
        })}
    </div>
  )
}

const Submenu = ({ items, isOpen, itemClassName = '' }) => {
  return (
    <div className={cn(styles.submenu, { [styles.openSubmenu]: isOpen })}>
      <ul>
        {items.map((item, idx) => {
          return (
            <MenuItem
              key={idx}
              item={item}
              isSubmenu
              className={itemClassName}
              hasSubmenu={item.subMenu.length > 0}
            >
              {item.subMenu.length > 0 && (
                <Submenu
                  items={item.subMenu}
                  itemClassName={styles.subSubMenuItem}
                />
              )}
            </MenuItem>
          )
        })}
      </ul>
    </div>
  )
}

const MenuItem = ({
  item,
  children,
  hasSubmenu,
  isSubmenu,
  languageBtn,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const title = item.title ? item.title : item
  const toggleSubMenu = () => {
    setIsOpen(!isOpen)
  }
  return (
    <li key={item.url}>
      <span
        className={cn(
          styles.menuItem,
          { [styles.subMenuItem]: isSubmenu },
          className,
          ' d-flex justify-content-between align-items-center'
        )}
      >
        <Link className={cn('link link--navigation')} to={item.url}>
          {title}
        </Link>
        {hasSubmenu && (
          <button
            className={cn(
              'button-no-style d-flex align-items-center p-1',
              styles.arrow,
              { [styles.rotated]: isOpen && !languageBtn }
            )}
            onClick={() => toggleSubMenu()}
          >
            {languageBtn ? (
              <FontAwesomeIcon icon={faGlobe} size='sm' />
            ) : (
              <Arrow />
            )}
          </button>
        )}
      </span>
      {isOpen && <span>{children}</span>}
    </li>
  )
}

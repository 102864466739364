import React, { useMemo } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useSearch, tokenize } from '@kogk/gatsby-plugin-js-search'
import { Link } from '@kogk/gatsby-theme-base'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import GridLayout from '@cmp/site/GridLayout'
import { Search } from '@src/assets'
import { formatDateString } from '@src/utils'

/**
 * honestly, this is probably not a good idea, but we have
 * a very limited use case for now
 * @param {string} query
 */
const parseQueryString = query => {
  const [str] = query.split('?').reverse()

  return str
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [decodeURIComponent(key)]: decodeURIComponent(val)
      }),
      {}
    )
}

export default () => {
  return (
    <GridLayout>
      <SearchField />
    </GridLayout>
  )
}

const SearchField = props => {
  const { t } = useLanguage()
  const { pathname, search: queryString } = useLocation()
  const { q: qraw = '' } = parseQueryString(queryString)
  const q = qraw.trim()

  return (
    <>
      {q &&
        <h2>{t(`Search strings|Search results`)} “{q}”</h2>
      }

      <div className='d-flex py-2'>
        <div className='w-100'>
          <input
            placeholder='Your search'
            className='form-control'
            type='text'
            defaultValue={q}
            onKeyUp={e => {
              if (e.key !== 'Enter') {
                return
              }

              const newPath = `${pathname}?q=${encodeURIComponent(
                e.target.value
              )}`

              navigate(newPath, { replace: true })
            }}
          />
        </div>
        <div className='search-icon'>
          <Search />
        </div>
      </div>

      {useMemo(() => q.length > 0 && <SearchResults term={q} />, [q])}
    </>
  )
}

const SearchResults = ({ term }) => {
  const { language } = useLanguage()
  const search = useSearch(language)
  const { origin } = useLocation()

  if (!search) {
    return null
  }

  return (
    <ol className='pl-0'>
      {search(term).map(result => {
        return (
          <li key={result.url} className='search-result-row py-3' >
            {result.data.date &&
              <p className='eyebrow mb-1'>{formatDateString(result.data.date)}</p>
            }
            <Link className='link link--bigger link--no-text-trans' to={result.url}>
              <h2 className='pb-1'>{result.title}</h2>
            </Link>
            <MatchedText term={term} texts={result.text} />
          </li>
        )
      })}
    </ol>
  )
}

const MatchedText = ({ term, texts, date, ...rest }) => {
  if (!texts) {
    return null
  }
  const wordRegexes = tokenize(term).map(word => new RegExp(`(${word})`, 'gi'))
  const highestRankedText = getHighestRankedText(texts, wordRegexes).split(' ').slice(0, 30).join(' ').toString() + '...'
  if (!highestRankedText) {
    const [first] = texts

    return first
  }

  const __html = wordRegexes.reduce(
    (html, regex) => html.replace(regex, match => `<strong>${match}</strong>`),
    highestRankedText
  )

  return (
    <p
      className='parag-small mb-0'
      dangerouslySetInnerHTML={{
        __html
      }}
      {...rest}
    />
  )
}

const getHighestRankedText = (texts, wordRegexes) => {
  const { text = null } = texts
    .map(text => ({
      text,
      score: computeScore(text, wordRegexes)
    }))
    .reduce((best, curr) => (curr.score > best.score ? curr : best), {
      score: -1
    })

  return text
}

const computeScore = (text, wordRegexes) => {
  return wordRegexes
    .map(regex => countMatches(text, regex))
    .map(count => Math.sqrt(count))
    .reduce((sum, count) => sum + count, 0)
}

const countMatches = (text, regex) => {
  const matches = text.match(regex)

  return matches ? matches.length : 0
}

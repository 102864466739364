import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import {
  MenuDesktop,
  MenuMobile,
  Burger
} from '@cmp/site'

import { useGlobalData } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

import Logo from '../../../assets/logo/LogoBlue.js'
import styles from './NavBar.module.scss'
import cn from 'classnames'
import { Search } from '@src/assets'
import { SearchModal } from '@src/components/sections'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

export default ({ altUrls }) => {
  const { mainMenu, isMobileNavBar } = useGlobalData()
  const { t, language } = useLanguage()

  const logoUrl = language === 'is' ? `/${language}` : '/'

  const [scroll, setScroll] = useState(true)
  const [isActive, setIsAcitive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = () => {
    return setIsOpen(!isOpen)
  }

  const setAcitve = () => {
    const body = document.body
    body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome,

    setIsAcitive(!isActive)
  }

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 40
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  return (
    <div
      className={styles.container}>
      <nav
        className={cn(
          { [styles.scrolled]: !scroll && !isMobileNavBar },
          styles.header
        )}>

        <Container>
          <Row>
            <Col
              col={12}
              className={styles.column}
            >

              <div className='mr-auto'>
                <Link to={logoUrl} className={cn(styles.logo, 'd-inline-block')} title={t`go home`}>
                  <Logo />
                </Link>
              </div>

              {!isMobileNavBar &&

                <div className='d-flex align-items-center'>
                  <MenuDesktop
                    altUrls={altUrls}
                    items={mainMenu}
                    title={t`top level links`}
                  />

                  <span className={styles.searchIcon}>
                    <button
                      className='button-no-style'
                      onClick={() => toggleModal()}
                    >
                      <Search />
                    </button>
                  </span>
                </div>
              }

              {isMobileNavBar &&
                <div className='d-block d-xxl-none'>
                  <Burger
                    onClick={() => setAcitve()} active={isActive}
                  />
                  <MenuMobile
                    altUrls={altUrls}
                    isActive={isActive}
                    items={mainMenu}
                  />
                </div>
              }

            </Col>
          </Row>
        </Container>

        <SearchModal
          isOpen={isOpen}
        >
          <button
            onClick={() => toggleModal()}
            className={cn(
              styles.closeBtn,
              'button-no-style'
            )}>
            <FontAwesomeIcon
              size='2x'
              icon={faTimes}
            />
          </button>
        </SearchModal>

      </nav>
    </div>
  )
}

import React from 'react'
import { Row, Col, Container, FancyShow } from '@kogk/common'
import styles from './ShortcutButtons.module.scss'
import cn from 'classnames'
import NewsIcon from './icons/NewsIcon'
import QandAIcon from './icons/QandAIcon'
import TechnologyIcon from './icons/TechnologyIcon'
import ConsultationIcon from './icons/ConsultationIcon'

const icons = {
  news: <NewsIcon />,
  qAndA: <QandAIcon />,
  technology: <TechnologyIcon />,
  consultation: <ConsultationIcon />
}

const ShortcutButton = ({button}) => (
  <>
    <div
      className={cn(styles.shortcutButton, 'p-3 d-flex justify-content-center')}
    >
      {icons[button.icon]} 
    </div>
    <p className={cn(styles.shortcutButtonLabel, 'pt-1 parag-big text-center')}>
      {button.label}
    </p>
  </>
)

export default ({ buttons }) => {
  // Prismic automatically adds "https://" to ID links
  const getIdIfExists = link =>
    link.includes('https://#') ? link.split('https://#')[1] : undefined


  const scrollTo = (id) => {
    if(id === 'taeknin') {
      const h2Tags = document.getElementsByTagName("h2");
      for (const h2 of h2Tags) {
        if (h2.textContent.trim() == "Coda verkefnið frá A til Ö") {
          h2.scrollIntoView({block: "center", behavior: 'smooth'});
          setTimeout(() => window.scrollBy({top: 250, behavior: "smooth"}), 100)
          break;
        }
      }
    } else if(id === 'q-and-a') {
      const element = document.getElementById(id);
      element.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }
  return (
    <FancyShow>
      <section className='py-2'>
        <Container>
          <Row>
            {buttons
              .filter(button => icons[button.icon] !== undefined)
              .map((button, idx) => (
                <Col
                  col={{ lg: 3 }}
                  className='pt-2 pt-lg-0 col-md-6'
                  key={idx}
                >
                  <div className='d-flex flex-column align-items-center'>
                    {getIdIfExists(button.link) === undefined ? (
                      <a href={button.link}>
                        <ShortcutButton button={button} />
                      </a>
                    ) : (
                      <a role="button" onClick={() => scrollTo(getIdIfExists(button.link))}>
                        <ShortcutButton button={button} />
                      </a>
                    )}
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </FancyShow>
  )
}

import React from "react";
import { Row, Col, Container, FancyShow, RawHtml } from "@kogk/common";
import { SliceWrapper } from "@cmp/site";
import cn from "classnames";

import styles from "./JumpPointSection.module.scss";
import { customSlugify } from "@src/utils";

export default ({ slug }) => {
  return (
    <div>
      <a
        className={cn("block invisible", styles.jumpPoint)}
        id={customSlugify(slug ?? "")}
      />
    </div>
  );
};

import React from 'react'

/* eslint max-len: ['error', {'code': 15000}] */

const SvgComponent = () => (
  <svg width='3162px' height='329px' viewBox='0 0 3162 329' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <title>Carbfix</title>
    <g id='Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Carbfix-svg-BG' transform='translate(1.000000, 0.000000)'>
        <g>
          <path d='M0,198.552124 L28.6645278,191.599457 L71.1976688,214.054054 L97.8770995,202.826755 L139.753171,214.054054 L189.438156,220 L231.331929,220 C243.19596,216.036036 249.127975,214.054054 249.127975,214.054054 C249.127975,214.054054 258.750838,216.036036 277.996563,220 L350.391719,220 L370.464204,214.054054 L378.590895,202.826755 L405.512104,198.552124 L449.451503,214.054054 L483.813544,214.054054 C502.339967,203.719434 511.603179,198.552124 511.603179,198.552124 C511.603179,198.552124 511.603179,193.075456 511.603179,182.122122 L531.950138,173.659374 L560.067457,165 C578.563264,165.131083 588.847396,168.017541 590.919855,173.659374 C592.992313,179.301206 597.417292,185.581415 604.194793,192.5 L632.411794,208.442371 L696.786407,208.442371 L692.235803,192.5 C712.592746,185.581415 726.908618,182.122122 735.18342,182.122122 C747.595623,182.122122 817.631818,194.277492 817.631818,198.552124 C817.631818,201.401878 826.993977,195.925211 845.718296,182.122122 L896.804421,198.552124 L950.85866,208.442371 C983.713042,201.848873 1002.55596,198.552124 1007.3874,198.552124 C1012.21885,198.552124 1039.06875,201.848873 1087.93711,208.442371 L1129.4686,202.826755 L1183.02483,208.442371 L1205.16476,214.054054 L1223.63855,208.442371 C1249.54331,200.954883 1262.49568,199.083012 1262.49568,202.826755 C1262.49568,206.570499 1278.19972,206.570499 1309.6078,202.826755 L1309.6078,191.599457 C1305.01238,190.923066 1305.01238,187.763954 1309.6078,182.122122 C1316.50093,173.659374 1354.24292,173.659374 1354.24292,173.659374 C1354.24292,173.659374 1401.56721,190.698913 1401.56721,191.599457 C1401.56721,192.199819 1414.37814,195.942252 1440,202.826755' id='Path-2-Copy' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' />
          <path d='M1440,202.826755 L1468.66453,191.599457 L1511.19767,214.054054 L1537.8771,202.826755 L1579.75317,214.054054 L1629.43816,220 L1671.33193,220 C1683.19596,216.036036 1689.12798,214.054054 1689.12798,214.054054 C1689.12798,214.054054 1698.75084,216.036036 1717.99656,220 L1790.39172,220 L1810.4642,214.054054 L1818.5909,202.826755 L1845.5121,198.552124 L1889.4515,214.054054 L1923.81354,214.054054 C1942.33997,203.719434 1951.60318,198.552124 1951.60318,198.552124 C1951.60318,198.552124 1951.60318,193.075456 1951.60318,182.122122 L1971.95014,173.659374 L2000.06746,165 C2018.56326,165.131083 2028.8474,168.017541 2030.91985,173.659374 C2032.99231,179.301206 2037.41729,185.581415 2044.19479,192.5 L2072.41179,208.442371 L2136.78641,208.442371 L2132.2358,192.5 C2152.59275,185.581415 2166.90862,182.122122 2175.18342,182.122122 C2187.59562,182.122122 2257.63182,194.277492 2257.63182,198.552124 C2257.63182,201.401878 2266.99398,195.925211 2285.7183,182.122122 L2336.80442,198.552124 L2390.85866,208.442371 C2423.71304,201.848873 2442.55596,198.552124 2447.3874,198.552124 C2452.21885,198.552124 2479.06875,201.848873 2527.93711,208.442371 L2569.4686,202.826755 L2623.02483,208.442371 L2645.16476,214.054054 L2663.63855,208.442371 C2689.54331,200.954883 2702.49568,199.083012 2702.49568,202.826755 C2702.49568,206.570499 2718.19972,206.570499 2749.6078,202.826755 L2749.6078,191.599457 C2745.01238,190.923066 2745.01238,187.763954 2749.6078,182.122122 C2756.50093,173.659374 2794.24292,173.659374 2794.24292,173.659374 C2794.24292,173.659374 2841.56721,190.698913 2841.56721,191.599457 C2841.56721,192.199819 2854.37814,197.367129 2880,207.101387' id='Path-2-Copy-5' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' />
          <path d='M0,305.552124 L28.6645278,298.599457 L71.1976688,321.054054 L97.8770995,309.826755 L139.753171,321.054054 L189.438156,327 L231.331929,327 C243.19596,323.036036 249.127975,321.054054 249.127975,321.054054 C249.127975,321.054054 258.750838,323.036036 277.996563,327 L350.391719,327 L370.464204,321.054054 L378.590895,309.826755 L405.512104,305.552124 L449.451503,321.054054 L483.813544,321.054054 C502.339967,310.719434 511.603179,305.552124 511.603179,305.552124 C511.603179,305.552124 511.603179,300.075456 511.603179,289.122122 L531.950138,280.659374 L560.067457,272 C578.563264,272.131083 588.847396,275.017541 590.919855,280.659374 C592.992313,286.301206 597.417292,292.581415 604.194793,299.5 L632.411794,315.442371 L696.786407,315.442371 L692.235803,299.5 C712.592746,292.581415 726.908618,289.122122 735.18342,289.122122 C747.595623,289.122122 817.631818,301.277492 817.631818,305.552124 C817.631818,308.401878 826.993977,302.925211 845.718296,289.122122 L896.804421,305.552124 L950.85866,315.442371 C983.713042,308.848873 1002.55596,305.552124 1007.3874,305.552124 C1012.21885,305.552124 1039.06875,308.848873 1087.93711,315.442371 L1129.4686,309.826755 L1183.02483,315.442371 L1205.16476,321.054054 L1223.63855,315.442371 C1249.54331,307.954883 1262.49568,306.083012 1262.49568,309.826755 C1262.49568,313.570499 1278.19972,313.570499 1309.6078,309.826755 L1309.6078,298.599457 C1305.01238,297.923066 1305.01238,294.763954 1309.6078,289.122122 C1316.50093,280.659374 1354.24292,280.659374 1354.24292,280.659374 C1354.24292,280.659374 1401.56721,297.698913 1401.56721,298.599457 C1401.56721,299.199819 1414.37814,302.942252 1440,309.826755' id='Path-2-Copy-3' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' transform='translate(720.000000, 299.500000) scale(1, -1) translate(-720.000000, -299.500000) ' />
          <path d='M1440,309.826755 L1468.66453,298.599457 L1511.19767,321.054054 L1537.8771,309.826755 L1579.75317,321.054054 L1629.43816,327 L1671.33193,327 C1683.19596,323.036036 1689.12798,321.054054 1689.12798,321.054054 C1689.12798,321.054054 1698.75084,323.036036 1717.99656,327 L1790.39172,327 L1810.4642,321.054054 L1818.5909,309.826755 L1845.5121,305.552124 L1889.4515,321.054054 L1923.81354,321.054054 C1942.33997,310.719434 1951.60318,305.552124 1951.60318,305.552124 C1951.60318,305.552124 1951.60318,300.075456 1951.60318,289.122122 L1971.95014,280.659374 L2000.06746,272 C2018.56326,272.131083 2028.8474,275.017541 2030.91985,280.659374 C2032.99231,286.301206 2037.41729,292.581415 2044.19479,299.5 L2072.41179,315.442371 L2136.78641,315.442371 L2132.2358,299.5 C2152.59275,292.581415 2166.90862,289.122122 2175.18342,289.122122 C2187.59562,289.122122 2257.63182,301.277492 2257.63182,305.552124 C2257.63182,308.401878 2266.99398,302.925211 2285.7183,289.122122 L2336.80442,305.552124 L2390.85866,315.442371 C2423.71304,308.848873 2442.55596,305.552124 2447.3874,305.552124 C2452.21885,305.552124 2479.06875,308.848873 2527.93711,315.442371 L2569.4686,309.826755 L2623.02483,315.442371 L2645.16476,321.054054 L2663.63855,315.442371 C2689.54331,307.954883 2702.49568,306.083012 2702.49568,309.826755 C2702.49568,313.570499 2718.19972,313.570499 2749.6078,309.826755 L2749.6078,298.599457 C2745.01238,297.923066 2745.01238,294.763954 2749.6078,289.122122 C2756.50093,280.659374 2794.24292,280.659374 2794.24292,280.659374 C2794.24292,280.659374 2841.56721,297.698913 2841.56721,298.599457 C2841.56721,299.199819 2854.37814,304.367129 2880,314.101387' id='Path-2-Copy-6' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' transform='translate(2160.000000, 299.500000) scale(1, -1) translate(-2160.000000, -299.500000) ' />
          <path d='M0,90.5521236 L31.4513569,83.5994566 L78.1196643,106.054054 L107.392929,94.8267553 L153.340285,106.054054 L207.855754,112 L253.822534,112 C266.840011,108.036036 273.34875,106.054054 273.34875,106.054054 C273.34875,106.054054 283.907169,108.036036 305.024007,112 L384.457581,112 L406.481557,106.054054 L415.398344,94.8267553 L444.936892,90.5521236 L493.148177,106.054054 L530.850972,106.054054 C551.178575,95.7194337 561.342376,90.5521236 561.342376,90.5521236 C561.342376,90.5521236 561.342376,85.0754564 561.342376,74.1221221 L583.667513,65.6593737 L614.51846,57 C634.81247,57.1310835 646.096449,60.0175414 648.370396,65.6593737 C650.644344,71.301206 655.499529,77.5814147 662.935953,84.5 L693.896274,100.442371 L764.52953,100.442371 L759.536506,84.5 C781.872596,77.5814147 797.58029,74.1221221 806.659586,74.1221221 C820.278531,74.1221221 897.1238,86.2774918 897.1238,90.5521236 C897.1238,93.4018781 907.396169,87.9252109 927.940908,74.1221221 L983.99374,90.5521236 L1043.30325,100.442371 C1079.35181,93.8488727 1100.02667,90.5521236 1105.32784,90.5521236 C1110.62901,90.5521236 1140.08932,93.8488727 1193.70877,100.442371 L1239.27804,94.8267553 L1298.04113,100.442371 L1322.33356,106.054054 L1342.60341,100.442371 C1371.02668,92.9548835 1385.23832,91.0830116 1385.23832,94.8267553 C1385.23832,98.5704991 1402.46914,98.5704991 1436.93078,94.8267553 L1436.93078,83.5994566 C1454.54584,89.8416512 1465.87447,90.1418323 1470.91667,84.5 C1478.47997,76.0372515 1498.0625,100.442371 1498.0625,100.442371 C1498.0625,100.442371 1537.83069,82.6989132 1537.83069,83.5994566 C1537.83069,84.1998189 1551.88713,87.9422518 1580,94.8267553' id='Path-2-Copy-2' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' />
          <path d='M1580,94.8267553 L1611.45136,83.5994566 L1658.11966,106.054054 L1687.39293,94.8267553 L1733.34028,106.054054 L1787.85575,112 L1833.82253,112 C1846.84001,108.036036 1853.34875,106.054054 1853.34875,106.054054 C1853.34875,106.054054 1863.90717,108.036036 1885.02401,112 L1964.45758,112 L1986.48156,106.054054 L1995.39834,94.8267553 L2024.93689,90.5521236 L2073.14818,106.054054 L2110.85097,106.054054 C2131.17857,95.7194337 2141.34238,90.5521236 2141.34238,90.5521236 C2141.34238,90.5521236 2141.34238,85.0754564 2141.34238,74.1221221 L2163.66751,65.6593737 L2194.51846,57 C2214.81247,57.1310835 2226.09645,60.0175414 2228.3704,65.6593737 C2230.64434,71.301206 2235.49953,77.5814147 2242.93595,84.5 L2273.89627,100.442371 L2344.52953,100.442371 L2339.53651,84.5 C2361.8726,77.5814147 2377.58029,74.1221221 2386.65959,74.1221221 C2400.27853,74.1221221 2477.1238,86.2774918 2477.1238,90.5521236 C2477.1238,93.4018781 2487.39617,87.9252109 2507.94091,74.1221221 L2563.99374,90.5521236 L2623.30325,100.442371 C2659.35181,93.8488727 2680.02667,90.5521236 2685.32784,90.5521236 C2690.62901,90.5521236 2720.08932,93.8488727 2773.70877,100.442371 L2819.27804,94.8267553 L2878.04113,100.442371 L2902.33356,106.054054 L2922.60341,100.442371 C2951.02668,92.9548835 2965.23832,91.0830116 2965.23832,94.8267553 C2965.23832,98.5704991 2982.46914,98.5704991 3016.93078,94.8267553 L3016.93078,83.5994566 C3034.54584,89.8416512 3045.87447,90.1418323 3050.91667,84.5 C3058.47997,76.0372515 3078.0625,100.442371 3078.0625,100.442371 C3078.0625,100.442371 3117.83069,82.6989132 3117.83069,83.5994566 C3117.83069,84.1998189 3131.88713,89.367129 3160,99.1013871' id='Path-2-Copy-4' stroke='#FFFFFF' strokeWidth='3' opacity='0.049999997' />
          <rect id='Rectangle' fill='#FFFFFF' x='0' y='0' width='3000' height='3' />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent

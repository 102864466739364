import React from 'react'
import { FancyShow, handleImgixUrl, RawHtml } from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import Img from 'gatsby-image'
import style from './Hero.module.scss'
import cn from 'classnames'

export default ({
  caption,
  imageAlt,
  imageUrlDesktop,
  imageUrlMobile
}) => {
  const mobileImg = imageUrlMobile ? imageUrlMobile : imageUrlDesktop

  return (
        <div className={style.container}>
          {imageUrlDesktop && (
            <Img
              loading='lazy'
              className={cn('d-none d-sm-block', style.imgBottom)}
              fluid={handleImgixUrl(imageUrlDesktop).gatsbyImageFluid()}
              alt={imageAlt}
            />
          )}

          {mobileImg && (
            <Img
              loading='lazy'
              className={cn('d-sm-none', style.imgBottom)}
              fluid={handleImgixUrl(mobileImg).gatsbyImageFluid()}
              aria-hidden='true'
              alt={imageAlt}
            />
          )}

          <RawHtml el='div' className={style.imageText}>
            {caption}
          </RawHtml>
        </div>
  )
}

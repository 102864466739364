import React, { useState } from "react";
import { Row, Col, Container, FancyShow, RawHtml } from "@kogk/common";
import { SliceWrapper } from "@cmp/site";
import cn from "classnames";

import styles from "./JumpLinkSection.module.scss";
import { customSlugify } from "@src/utils";
import { ArrowDown } from "@src/assets/index";
import { Link } from "@kogk/gatsby-theme-base";

export default ({ html, items }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <SliceWrapper textCenter={false}>
      <Container className={cn("pt-4", styles.allContainer)}>
        <RawHtml el="div" className="pb-3">
          {html}
        </RawHtml>
        <Row className={styles.rowContainer}>
          {items
            ? items.map((link, index) => {
                return (
                  <Link
                    href={
                      !!link.outside_link.url
                        ? link.outside_link.url
                        : `#${customSlugify(link.jump_link_title.text ?? "")}`
                    }
                    className={cn(
                      "d-flex flex-column align-items-center",
                      styles.card
                    )}
                    onMouseEnter={() => setHoveredIndex(index)} // Set hover state
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
                  >
                    <Col key={index}>
                      <RawHtml el="div" className={styles.richText}>
                        {link.card_text.html}
                      </RawHtml>
                      <ArrowDown longerLine={hoveredIndex === index} />
                    </Col>
                  </Link>
                );
              })
            : null}
        </Row>
      </Container>
    </SliceWrapper>
  );
};
